
    import Vue from "vue";
    import Component from "vue-class-component";
    import { ApexOptions } from "apexcharts";
    import { DashboardData, IApexBarChartData, IApexChartData, IDashboardData } from "@/model/DashboardData";
    import { ILookupItem, LookupItem } from "@/model/LookupItem";
    import { RegionLookupItem } from "@/model/RegionLookupItem";
    import { DashboardSearchParameters as SearchParameters } from "@/model/DashboardSearchParameters";
    import store from "@/store/store";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import { Watch } from "vue-property-decorator";

    @Component({
        components: {  }
    })
    export default class Dashboard extends Vue {

        //
        // -- properties
        //

        get signedInUserName(): string { return store.getters.signedInUserName; }

        isBuyerZone: boolean = false;
        parameters: SearchParameters = new SearchParameters();
        
        buyerList: Array<ILookupItem> = [];
        buyerOptions: Array<ILookupItem> = [];

        regionList: Array<RegionLookupItem> = [];
        get regionOptions(): Array<ILookupItem> { 
            const filtered = this.regionList.filter(lu => lu.id > 0 && !lu.isArchived && lu.buyerID === this.parameters.buyerID);
            if (!filtered.find(r => r.id == this.parameters.regionID)) this.parameters.regionID = 0;
            return filtered.length === 0 
                ? [ new LookupItem( { id: 0, description:  "No regions available", isArchived: false }) ]
                : [ new LookupItem( { id: 0, description:  "Any region", isArchived: false }), ...filtered];
        }

        //
        // -- watchers
        //

        @Watch("parameters.buyerID")
        private onBuyerChanged() {
            this.fetchData();
        }

        @Watch("parameters.regionID")
        private onRegionchanged() {
            this.fetchData();
        }

        //
        // -- methods
        //

        async mounted(): Promise<void> {
            await Promise.all([
                this.loadBuyerList(),
                this.loadRegionList()
            ]);

            this.isBuyerZone = this.$router.currentRoute.path.toLowerCase().indexOf("buyerzone") > -1;
            this.parameters.buyerID = this.isBuyerZone ? store.state.signedInUser == null ? 0 : +store.state.signedInUser.buyerID : 0;
        }

        private async loadBuyerList() {
            this.buyerList = await apiClient.get("/api/buyer/lookups?supplierID=0");
            this.buyerOptions = utils.selectOptions(this.buyerList, "Any buyer");
        }

        private async loadRegionList() {
            this.regionList = await apiClient.get("api/region/lookups");
        }

        private async fetchData() {
            this.parameters.compliant = true;
            this.parameters.nonCompliant = true;
            const response: IDashboardData = await apiClient.post("api/buyer/dashboard", this.parameters);
            this.chartData.update(response);
        }

        private async fetchInsuranceData() {
            const response: IApexChartData = await apiClient.post("api/buyer/dashboard/insuranceValidity", this.parameters);
            this.chartData.insurance.update(response);
        }

        private async fetchCompanySizeData() {
            const response: IApexBarChartData = await apiClient.post("api/buyer/dashboard/companySize", this.parameters);
            this.chartData.companySize.update(response);
        }

        private async onCompliantLegendClicked(_chart: unknown, seriesIndex: number): Promise<void> {
            this.parameters.compliant = seriesIndex == 0 ? !this.parameters.compliant : this.parameters.compliant;
            this.parameters.nonCompliant = seriesIndex == 1 ? !this.parameters.nonCompliant : this.parameters.nonCompliant;
            
            await Promise.all([
                this.fetchInsuranceData(),
                this.fetchCompanySizeData()
            ]);
        }

        //
        // -- charts
        //

        chartData: DashboardData = new DashboardData();

        get complianceData(): Array<number> {
            return this.chartData.compliance.data.length > 0 ? this.chartData.compliance.data : [0, 0]
        }
        get complianceOptions(): ApexOptions  {
            return {
                chart: {
                    events: {
                        legendClick: this.onCompliantLegendClicked
                    }
                },
                title: {
                    text: "Supplier Compliance",
                    style: {
                        fontSize: "20px"
                    }
                },
                plotOptions: {
                    radialBar: {
                        startAngle: 0,
                        endAngle: 270,
                        hollow: {
                            margin: 5,
                            size: "35%"
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                show: false
                            }
                        }                        
                    }
                },
                labels: this.chartData.compliance.categories,
                legend: {
                    show: true,
                    floating: true,
                    fontSize: '16px',
                    position: 'left',
                    offsetX: 70,
                    offsetY: 42,
                    labels: {
                        useSeriesColors: true,
                    },
                    itemMargin: {
                        vertical: 4
                    },
                    formatter: (seriesName, opts) => {
                        return `${seriesName} (${opts.w.globals.series[opts.seriesIndex]}%)`;
                    },
                }
            }
        }

        get insuranceStatusData(): Array<number> { 
            return this.chartData.insurance.data.length > 0 ? this.chartData.insurance.data : [0, 0, 0]
        }
        get insuranceStatusOptions(): ApexOptions {
            return {
                title: {
                    text: "Insurance Validity",
                    style: {
                        fontSize: "20px"
                    }
                },
                plotOptions: {
                    radialBar: {
                        startAngle: 0,
                        endAngle: 270,
                        hollow: {
                            margin: 5,
                            size: "15%"
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                show: false
                            }
                        }
                    }
                },
                labels: this.chartData.insurance.categories,
                legend: {
                    show: true,
                    floating: true,
                    fontSize: '16px',
                    position: 'left',
                    offsetX: 40,
                    offsetY: 45,
                    labels: {
                        useSeriesColors: true,
                    },
                    itemMargin: {
                        vertical: 2
                    },
                    formatter: (seriesName, opts) => {
                        return `${seriesName} (${opts.w.globals.series[opts.seriesIndex]}%)`;
                    }
                }
            }
        }

        get companySizeData(): Array<any> {
            return this.chartData.companySize.data.length > 0 ? this.chartData.companySize.data : [{ name: "Suppliers",  data: [0, 0, 0, 0, 0, 0 ] }]
        }
        get companySizeOptions(): ApexOptions {
            return {
                    title: {
                    text: "Company Size",
                    style: {
                        fontSize: "20px"
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: this.chartData.companySize.categories
                },
                yaxis: {
                    title: {
                        text: "Suppliers"
                    }
                }
            }
        }

    }

