import { mapData } from "@/stuff/DataMapping";

export interface IApexBarDataSet {
    name: string;
    data: Array<number>;
}

export interface IApexChartData {
    categories: Array<string>;
    data: Array<number>;
}

export interface IApexBarChartData {
    categories: Array<string>;
    data: Array<IApexBarDataSet>;
}

export interface IDashboardData {
    compliance: IApexChartData;
    insurance: IApexChartData;
    companySize: IApexBarChartData;
}

export class ApexBarDataSet implements IApexBarDataSet {

    constructor(data?:IApexBarDataSet) {
        if (data) this.update(data);
    }

    update(data: IApexBarDataSet): void {
        mapData(data, { root: () => this });
    }

    name: string = "";
    data: Array<number> = [];

}

export class ApexChartData implements IApexChartData {

    constructor(data?:IApexChartData) {
        if (data) this.update(data);
    }

    update(data: IApexChartData): void {
        mapData(data, { root: () => this });
    }

    categories: Array<string> = [];
    data: Array<number> = [];

}

export class ApexBarChartData implements IApexBarChartData {

    constructor(data?:IApexBarChartData) {
        if (data) this.update(data);
    }

    update(data:IApexBarChartData): void {
        mapData(data, { 
            root: () => this,
            data: (data: IApexBarDataSet) => new ApexBarDataSet(data)
        });
    }

    categories: Array<string> = [];
    data: Array<IApexBarDataSet> = [];

}

export class DashboardData implements IDashboardData {

    constructor(data?:IDashboardData) {
        if (data) this.update(data);
    }

    update(data:IDashboardData): void {
        mapData(data, {
            root: () => this,
            compliance: (data: IApexChartData) => new ApexChartData(data),
            insurance: (data: IApexChartData) => new ApexChartData(data),
            companySize: (data: IApexBarChartData) => new ApexBarChartData(data)
        });
    }

    compliance: ApexChartData = new ApexChartData();
    insurance: ApexChartData = new ApexChartData();
    companySize: ApexBarChartData = new ApexBarChartData();
}